import React from 'react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';
import SingleColPageLayout from '../templates/single-col-page-layout';
import MetaTags from '../components/MetaTags';

const getEmailsFromEdges = (postEdges) =>
  postEdges.map((postEdge) => ({
    path: postEdge.node.fields.slug,
    subject: postEdge.node.frontmatter.subject,
    description: postEdge.node.frontmatter.description,
    date: postEdge.node.fields.date,
  }));

const EmailsPage = ({ data, path }) => {
  const postList = getEmailsFromEdges(data.allMarkdownRemark.edges);
  return (
    <SingleColPageLayout>
      <MetaTags
        title="Email Archive"
        description="Archive of emails to my serverless newsletter"
        path={path}
        image="/img/daily-emails-banner_2022-02.png"
      />
      <h1 className="text-center">Email Archive</h1>
      <p className="text-center">
        To receive these emails to your inbox,{' '}
        <Link to="/newsletter">go here</Link>.
      </p>
      <div className="listing-container">
        {postList.map((post) => (
          <div
            className="grid grid-cols-1 md:grid-cols-3 gap-x-4 mb-4 md:mb-2 leading-snug"
            key={post.path}
          >
            <div className="whitespace-no-wrap text-gray-600 md:text-right">
              <span>{moment(post.date).format('MMMM D, YYYY')}</span>
            </div>
            <div className="md:col-span-2">
              <Link to={post.path}>{post.subject}</Link>
            </div>
          </div>
        ))}
      </div>
    </SingleColPageLayout>
  );
};

export default EmailsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "^/emails/" } } }
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          frontmatter {
            subject
            description
            date
          }
        }
      }
    }
  }
`;
